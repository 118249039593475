@import "src/styles/_mixins.scss";

.landing-contact--heading {
  margin-bottom: 5px;
}

.landing-contact--subheading {
  font-size: 18px;
}

.contact-form {
  margin-top: 30px;
}

.ContactForm--button-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
