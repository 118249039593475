@import "src/styles/_mixins.scss";

body {
  margin: 0;
  padding: 0;
  font-family: "open sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif;
  background: #ffffff;
  font-size: 14px;
  &.overlay-open {
    overflow: hidden;
  }
}

.App {
  overflow-x: hidden;
}

.container {
  max-width: 1160px;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
}

/****** box-sizing reset ******/

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/****** text ******/

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 1px;
  font-weight: 400;
  margin-bottom: 0.5em;
  font-family: "open sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif;
}

h1 {
  font-size: 56px;
  line-height: 1.25;
}

h2 {
  font-size: 50px;
  line-height: 1.25;
}

h3 {
  font-size: 36px;
  line-height: 1.25;
}

h4 {
  font-size: 1.4em;
  line-height: 1.25;
}

h3.small {
  font-size: 1.3em;
}

p {
  font-size: 1.1em;
  line-height: 1.5;
  color: $shade-60;
  font-weight: 300;
}

p.small {
  font-size: 1em;
}

a {
  text-decoration: none;
  color: $tanMedium;
  &:hover {
    color: $tanDark;
  }
  &:active {
    color: $tanDark;
  }
}

b {
  font-weight: 500;
}

@include tablet {
  h1 {
    font-size: 50px;
    line-height: 1.25;
  }

  h2 {
    font-size: 42px;
    line-height: 1.25;
  }

  h3 {
    font-size: 36px;
    line-height: 1.25;
  }
}

@include phablet {
  h1 {
    font-size: 40px;
    line-height: 1.25;
  }

  h2 {
    font-size: 36px;
    line-height: 1.25;
  }

  h3 {
    font-size: 30px;
    line-height: 1.25;
  }
}

/****** buttons ******/

button,
.button {
  display: inline-block;
  position: relative;
  font-size: 16px;
  letter-spacing: 1px;
  font-family: $brandFont;
  border-radius: 10px 20px;
  line-height: 48px;
  border: 1px solid $tanMedium;
  background-color: $tanMedium;
  color: #ffffff;
  padding: 0 2em;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    border: 1px solid $tanDark;
    background-color: $tanDark;
    color: #ffffff;
  }
  &:focus {
    outline: none;
    border: 1px solid $tanDark;
    background-color: $tanDark;
    color: #ffffff;
  }
  &.button-hollow {
    background: transparent;
    color: $tanMedium;
    &:hover {
      border: 1px solid $tanMedium;
      color: $tanDark;
    }
  }
  &:disabled {
    opacity: 0.6;
    &:hover {
      border: 1px solid $tanMedium;
      background-color: $tanMedium;
    }
  }
  .icon {
    font-size: 1.4em;
    line-height: inherit;
    vertical-align: top;
    margin-right: 5px;
  }
}

.button-error {
  animation: shake 0.3s ease;
}

.secondary-action {
  font-size: 1.1em;
  color: $tan;
  letter-spacing: 1px;
  font-family: $brandFont;
  i {
    color: $tanLight;
    margin-left: 3px;
  }
  &:hover {
    color: $tanMedium;
    i {
      color: $tan;
    }
  }
}

.button-small {
  line-height: 43px;
  padding: 0 1.5em;
}

.button-container {
  margin-top: 50px;
  text-align: center;
}

.button-twitter {
  background: $twitter;
  border-color: $twitter;
  &:hover {
    background: $twitter-hover;
    border-color: $twitter-hover;
  }
}

.button-linkedin {
  background: $linkedin;
  border-color: $linkedin;
  &:hover {
    background: $linkedin-hover;
    border-color: $linkedin-hover;
  }
}

.button-email {
  background: $tan;
  border-color: $tan;
  &:hover {
    background: $tanMedium;
    border-color: $tanMedium;
  }
}

.button-slack {
  background: $slack;
  border-color: $slack;
  &:hover {
    background: $slack-hover;
    border-color: $slack-hover;
  }
}

.button-telegram {
  background: $telegram;
  border-color: $telegram;
  &:hover {
    background: $telegram-hover;
    border-color: $telegram-hover;
  }
}

.button-medium-blog {
  background: $mediumBlog;
  border-color: $mediumBlog;
  &:hover {
    background: $mediumBlog-hover;
    border-color: $mediumBlog-hover;
  }
}

/******* Social Buttons ******/

.social-buttons--list {
  display: flex;
}

.social-button {
  display: grid;
  align-items: center;
  justify-items: center;
  margin: 5px 10px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid #f7efa4;
  color: #f7efa4;
  font-size: 20px;
  &:hover {
    border: 1px solid #fff386;
    color: #fff386;
  }
}

.social-button--color {
  &.social-button--twitter {
    border-color: $twitter;
    color: $twitter;
    &:hover {
      border-color: $twitter-hover;
      color: $twitter-hover;
    }
  }
  &.social-button--slack {
    border-color: $slack;
    color: $slack;
    &:hover {
      border-color: $slack-hover;
      color: $slack-hover;
    }
  }
  &.social-button--telegram {
    border-color: $telegram;
    color: $telegram;
    &:hover {
      border-color: $telegram-hover;
      color: $telegram-hover;
    }
  }
  &.social-button--medium-blog {
    border-color: $mediumBlog;
    color: $mediumBlog;
    &:hover {
      border-color: $mediumBlog-hover;
      color: $mediumBlog-hover;
    }
  }
}

/****** Forms ******/

.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  margin-bottom: 10px;
  font-family: $brandFont;
  letter-spacing: 1px;
  font-size: 0.8em;
  font-weight: 500;
  text-transform: uppercase;
}

.form-input {
  display: block;
  width: 100%;
  line-height: 48px;
  padding: 0 15px;
  border-radius: 3px;
  background: #ffffff;
  border: 1px solid $shade-30;
  &:focus {
    outline: none;
    border-color: $shade-60;
  }
}

.form-input--error {
  border-color: $red;
}

input {
  transition: all 0.2s ease;
}

textarea.form-input {
  line-height: 22px;
  min-height: 50px;
  padding: 13px 15px;
  resize: none;
}

.form-button-group {
  display: flex;
  .form-button {
    margin-left: 30px;
    flex-grow: 0;
  }
}

.form-terms {
  font-size: 0.9em;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form-input--error-message {
  margin-top: 10px;
  color: $red;
  font-size: 0.8em;
}

.form-input--character-count {
  margin-top: 10px;
  color: $shade-60;
  font-size: 0.8em;
  text-align: right;
}

/****** landing ******/

.landing-section {
  padding-top: 40px;
  padding-bottom: 40px;
}

/****** card ******/

.card {
  border-radius: 10px;
  border: 1px solid $shade-20;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 30px;
  background: #ffffff;
  transition: all 0.2s ease-in-out;
  .icon-container {
    margin-bottom: 20px;
  }
}

.hoverable:hover {
  transform: translate3d(0px, -0.1em, 0px);
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.1);
}

.heading-divider-small {
  display: block;
  margin: 10px 0 10px;
}

.image-card {
  border-radius: 10px;
  border: 1px solid $shade-20;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  background: #ffffff;
  overflow: hidden;
}

.image-card--image {
  height: 150px;
  width: auto;
  display: flex;
  background-color: $shade-20;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.image-card--content {
  padding: 30px;
}

/****** icons ******/

.icon-container {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid $tanLight;
  background: #f0fcfb;
  color: $tanDark;
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    display: block;
    margin: 0 auto;
    max-width: 30px;
    max-height: 30px;
  }
}

.icon-container.large {
  width: 80px;
  height: 80px;
  img {
    max-width: 40px;
    max-height: 40px;
  }
}

/****** inverse colors ******/

.inverse {
  color: #ffffff;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: #ffffff;
  }
  a {
    color: $tanLight;
  }
  button,
  .button {
    color: #ffffff;
  }
}

/****** placeholder *******/

input::placeholder {
  color: inherit;
  opacity: 0.8;
}

/****** Loader ******/

@keyframes loader {
  to {
    transform: rotate(360deg);
  }
}

.loading {
  color: transparent !important;
  &:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin: -15px 0 0 -15px;
    border-radius: 100px;
    border: 0.2em solid rgba(255, 255, 255, 0.2);
  }
  &:after {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    width: 30px;
    height: 30px;
    margin: -15px 0 0 -15px;
    box-shadow: transparent 0px 0px 0px 1px;
    animation: loader 0.6s linear infinite;
    border-radius: 100px;
    border-color: rgba(255, 255, 255, 0.8) transparent transparent;
    border-style: solid;
    border-width: 0.2em;
  }
}

/****** Modal ******/

.ReactModal__Body--open {
  overflow: hidden !important;
}

.ReactModal__Overlay {
  transition: opacity 200ms ease-in-out;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2000;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  /* non-grid supported devices */
  display: flex;
  flex-direction: column;
  justify-content: center;
  &--after-open {
    opacity: 1;
  }

  &--before-close {
    opacity: 0;
  }
}

.ReactModal__Content {
  margin: 0 auto;
  transition: all 200ms ease-in-out;
  opacity: 0;

  &--after-open {
    opacity: 1;
  }

  &--before-close {
    opacity: 0;
  }
}

.modal-close {
  font-size: 30px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  a {
    color: $shade-40;
  }
  a:hover {
    color: $shade-50;
  }
}

.modal-heading {
  font-size: 2.2em;
  margin-bottom: 10px;
}

.modal-subheading {
  margin-bottom: 10px;
}

/* Responsive */

@include phablet {
  .ReactModal__Overlay {
    display: block;
  }
  .modal-close {
    font-size: 40px;
    top: 20px;
    right: 20px;
  }
}

/****** Form Modal ******/

.form-modal {
  position: relative;
  width: 100%;
  max-width: 600px;
  border: 1px solid $shade-20;
  background: rgb(255, 255, 255);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  overflow: auto;
  border-radius: 10px;
  outline: none;
  padding: 50px;
  transition: all 0.5 ease;
}

/* Responsive */

@include phablet {
  .form-modal {
    border-radius: 0;
    position: absolute;
    z-index: 2000;
    max-width: none;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border: none;
    box-shadow: none;
  }
}
