@import "src/styles/_mixins.scss";

.footer--content {
  padding: 60px 0;
}

/****** Footer Logo ******/

.footer--logo {
  display: flex;
  justify-content: center;
  img {
    height: 40px;
    width: auto;
    display: block;
  }
}
