/****** Colors ******/

$dark: #282A2C;

$tan: #E1C8AE;
$tanLight: #FFF3E7;
$tanMedium: #B89D80;
$tanDark: #A48A6F;

$red: #FE5F55;

$light: #F7FAFA;

$shade-0: #F7FAFA;
$shade-10: #F2F5F5;
$shade-20: #EDEFEF;
$shade-30: #DCDEDE;
$shade-40: #D0D3D3;
$shade-50: #8F9090;
$shade-60: #626767;

$twitter: #55acee;
$twitter-hover: #3682bc;

$linkedin: #0077b5;
$linkedin-hover: #016295;

$slack: #DF1764;
$slack-hover: #BC074D;

$telegram: #2397D3;
$telegram-hover: #137EB6;

$mediumBlog: #00AB6C;
$mediumBlog-hover: #018A57;

/****** Fonts ******/

$brandFont: 'open sans',-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;

/****** animations keyframes ******/

@keyframes spin-clockwise { 100% { transform:rotate(360deg); } }

@keyframes spin-counter-clockwise { 100% { transform:rotate(-360deg); } }

@keyframes shake {
  0% {
    transform: translate(.4em, 0);
  }
  25% {
    transform: translate(-.4em, 0);
  }
	50% {
    transform: translate(.4em, 0);
  }
  75% {
    transform: translate(-.4em, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

/****** Responsive ******/

@mixin tablet {
	@media screen and (max-width: 991px){
		@content;
	}
}

@mixin phablet {
	@media screen and (max-width: 767px){
		@content;
	}
}

@mixin mobile {
	@media screen and (max-width: 479px){
		@content;
	}
}
