@import "src/styles/_mixins.scss";

.Home {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1160px;
  margin: 0 auto;
  padding: 0 30px;
  position: relative;
}

@include phablet {
  .Home {
    padding: 0 20px;
  }
}

/****** Background ******/

.grain-shape--1 {
  position: absolute;
  right: 30px;
  top: -100px;
  z-index: -1;
  width: auto;
}

.grain-shape--2 {
  position: absolute;
  top: -400px;
  z-index: -1;
  transform: scaleX(-1);
  width: auto;
}

.rye-accent {
  width: 50px;
  height: auto;
}

@include tablet {
  .grain-shape--1 {
    right: 0;
    height: 1000px;
  }

  .rye-accent {
    width: 40px;
  }
}

@include phablet {
  .grain-shape--1 {
    height: 800px;
    right: -100px;
    top: -200px;
  }

  .rye-accent {
    width: 30px;
  }
}

/****** Banner ******/

.landing-banner {
  h1 {
    font-size: 55px;
    line-height: 1.5;
    font-weight: 400;
    margin-top: 250px;
  }
}

@include tablet {
  .landing-banner {
    h1 {
      margin-top: 200px;
      font-size: 40px;
    }
  }
}

@include phablet {
  .landing-banner {
    h1 {
      margin-top: 200px;
      font-size: 36px;
      br {
        display: none;
      }
    }
  }
}

/****** About ******/

.landing-about {
  margin-top: 150px;
  padding-left: 80px;
  position: relative;
  .rye-accent {
    position: absolute;
    left: 0;
    top: 11px;
  }
}

.landing-about--description {
  font-size: 22px;
  width: 80%;
  line-height: 2;
}

.landing-about--services {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.landing-about--service {
  flex-grow: 0;
  display: flex;
  margin-right: 50px;
}

.landing-about--service-icon {
  flex-grow: 0;
  height: 70px;
}

.landing-about--service-name {
  margin-left: 15px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@include tablet {
  .landing-about {
    margin-top: 100px;
    padding-left: 0;
    .rye-accent {
      top: -30px;
    }
  }

  .landing-about--description {
    font-size: 18px;
    width: 80%;
    line-height: 1.5;
  }

  .landing-about--service {
    margin-right: 30px;
    margin-bottom: 20px;
  }

  .landing-about--service-icon {
    height: 50px;
  }

  .landing-about--service-name {
    font-size: 16px;
    margin-left: 10px;
  }
}

@include phablet {
  .landing-about--description {
    font-size: 16px;
    width: 100%;
    line-height: 1.5;
  }

  .landing-about--service {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .landing-about--service-icon {
    height: 40px;
  }

  .landing-about--service-name {
    font-size: 14px;
    margin-left: 10px;
  }
}

/****** Work ******/

.landing-work {
  margin-top: 130px;
  .landing-work--description {
    margin-right: 500px;
  }
  .rye-accent {
    position: absolute;
    left: 0;
    top: 11px;
  }
}

.landing-work--heading {
  text-align: center;
}

.landing-work--description {
  font-size: 20px;
}

.landing-work--name-container {
  display: flex;
  margin-bottom: 30px;
}

.landing-work--name {
  flex-grow: 0;
  margin-bottom: 0;
}

.landing-work--name-link {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
  .icon {
    font-size: 28px;
  }
}

.landing-work--novo {
  margin-top: 80px;
  position: relative;
  padding-bottom: 510px;
  padding-left: 80px;
  position: relative;
}

.landing-work--finance {
  margin-top: 80px;
  position: relative;
  padding-bottom: 320px;
  padding-left: 80px;
  position: relative;
}

.landing-work--dunsguide-desktop-img {
  height: 480px;
  position: absolute;
  bottom: 0;
  right: 0px;
}

.landing-work--rye-finance-img {
  height: 480px;
  position: absolute;
  top: 100px;
  right: -180px;
}

@include tablet {
  .landing-work {
    margin-top: 100px;
    .rye-accent {
      top: -30px;
    }
    .landing-work--description {
      margin-right: 300px;
      font-size: 16px;
    }
  }

  .landing-work--novo {
    margin-top: 80px;
    padding-left: 0;
    padding-bottom: 430px;
  }

  .landing-work--finance {
    margin-top: 80px;
    padding-left: 0;
    padding-bottom: 0;
  }

  .landing-work--dunsguide-desktop-img {
    height: 400px;
  }

  .landing-work--rye-finance-img {
    height: 400px;
    position: relative;
    display: block;
    margin: 50px auto 0;
    top: auto;
    right: auto;
  }
}

@include phablet {
  .landing-work {
    .rye-accent {
      top: -20px;
    }
    .landing-work--description {
      margin-right: 0;
    }
  }

  .landing-work--heading {
    text-align: left;
  }

  .landing-work--novo {
    margin-top: 40px;
    padding-bottom: 0;
  }

  .landing-work--dunsguide-desktop-img {
    height: auto;
    width: 100%;
    position: relative;
    margin-top: 30px;
    right: 0;
    margin-bottom: 50px;
  }

  .landing-work--rye-finance-img {
    width: 90%;
    height: auto;
  }
}

/****** Team ******/

.landing-team {
  margin-top: 110px;
  position: relative;
}

.landing-team--heading {
  text-align: center;
}

.team-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 80px;
}

.team-member {
  flex: 0 0 48%;
  width: 50%;
  margin-bottom: 4%;
  display: flex;
  background: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  border-radius: 20px 100px;
  &:nth-child(even) {
    border-radius: 100px 20px;
    .team-member--image {
      border-radius: 100px 20px;
    }
  }
}

.team-member--image {
  flex: 0 0 190px;
  flex-grow: 0;
  display: block;
  width: 190px;
  height: 190px;
  border-radius: 20px 100px;
}

.team-member--info {
  padding: 10px 20px 10px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.team-member--name {
  font-size: 20px;
  margin-bottom: 5px;
}

.team-member--title {
  font-size: 16px;
}

.team-member--description {
  font-size: 12px;
  margin-top: 5px;
  font-weight: 400;
}

.team-member--additional {
  display: flex;
  margin-top: 20px;
}

.team-member--social {
  margin-left: 20px;
  a {
    margin-right: 10px;
    font-size: 18px;
  }
}

.team-member--location {
  color: $shade-50;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@include tablet {
  .landing-team {
    margin-top: 80px;
  }

  .landing-team--heading {
    margin-left: 0;
  }

  .team-content {
    margin-top: 40px;
  }

  .team-member--info {
    padding-left: 10px;
  }

  .team-member {
    border-radius: 10px 25px;
    &:nth-child(even) {
      border-radius: 25px 5px;
      .team-member--image {
        border-radius: 20px 5px;
      }
    }
  }

  .team-member--additional {
    margin-top: 10px;
  }

  .team-member--image {
    flex: 0 0 80px;
    width: 80px;
    height: 80px;
    border-radius: 5px 20px;
    margin: 10px;
  }
}

@include phablet {
  .landing-team--heading {
    text-align: left;
  }

  .team-member {
    flex: 1 0 100%;
    margin-bottom: 30px;
  }

  .team-member--name {
    font-size: 18px;
    margin-bottom: 0;
  }

  .team-member--title {
    font-size: 14px;
  }

  .team-member--description {
    font-size: 12px;
    margin-top: 5px;
    font-weight: 400;
  }
}

/****** Partners ******/

.landing-partners {
  margin: 40px auto 0;
}

.landing-partners--heading {
  font-size: 20px;
  color: $shade-50;
  margin-right: 60px;
  margin-bottom: 0;
  display: inline-block;
  vertical-align: middle;
}

.landing-partners--cial {
  height: 45px;
  margin-right: 60px;
  display: inline-block;
  vertical-align: middle;
}

.landing-partners--cb {
  height: 60px;
  display: inline-block;
  vertical-align: middle;
}

@include tablet {
  .landing-partners {
    margin-top: 30px;
  }
  .landing-partners--heading {
    font-size: 16px;
    margin-right: 40px;
  }
  .landing-partners--cial {
    height: 30px;
    margin-right: 40px;
    margin-bottom: 10px;
  }
  .landing-partners--cb {
    height: 40px;
    margin-bottom: 10px;
  }
}

@include phablet {
  .landing-partners {
    margin-top: 20px;
  }
  .landing-partners--heading {
    font-size: 16px;
    margin-right: 0;
    margin-bottom: 10px;
    display: block;
  }
  .landing-partners--cial {
    height: 25px;
  }
  .landing-partners--cb {
    height: 35px;
  }
}

/****** Contact ******/

.landing-contact {
  margin: 130px auto 0;
  max-width: 700px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  border-radius: 20px 100px;
  padding: 50px;
  position: relative;
  .rye-accent {
    position: absolute;
    left: -50px;
    top: 55px;
    width: 80px;
  }
}

@include tablet {
  .landing-contact {
    margin-top: 100px;
    max-width: 80%;
    padding: 30px;
    .rye-accent {
      width: 50px;
      left: -30px;
      top: 42px;
    }
  }
}

@include phablet {
  .landing-contact {
    margin-top: 80px;
    box-shadow: none;
    padding: 0px;
    max-width: 100%;
    .rye-accent {
      display: none;
    }
  }
}
