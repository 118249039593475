@import "src/styles/_mixins.scss";

.header {
  display: flex;
  height: 110px;
  padding: 30px 0;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2000;
}

/****** Logo *******/

.header--logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    height: 40px;
    width: auto;
  }
}

/****** Slide Menu ******/

.header-menu--container {
  position: relative;
  flex-grow: 1;
  display: flex;
}

/****** Navigation ******/

.header-menu--full {
  flex-grow: 1;
}

.header-nav--container {
  display: flex;
}

.header--nav {
  flex-grow: 1;
  display: flex;
  margin-left: 50px;
}

.header--nav--list {
  padding: 0;
}

.header--nav--list-item {
  display: inline-block;
  padding: 0 30px;
  a {
    color: $dark;
    font-size: 18px;
    letter-spacing: 0.1em;
    line-height: 50px;
    font-family: $brandFont;
    &:hover {
      color: $tanDark;
    }
  }
}

/******* Header CTA ******/

.header--cta {
  margin-left: 10px;
  .button {
    border: 1px solid $dark !important;
    color: $dark !important;
    &:hover {
      border: 1px solid #fff386 !important;
      color: #fff386 !important;
    }
  }
}

/****** Hamburger menu styles ******/

/* Container */
.header-menu--slide {
  display: none;
  .header--nav {
    justify-content: flex-start;
    margin-left: 0;
  }
  .header--nav--list-item {
    display: block;
    padding: 0 10px;
  }
  .header--cta {
    margin-left: 0px;
    margin-top: 15px;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 25px;
  height: 20px;
  top: 50%;
  margin-top: -10px;
  right: 0;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $tanDark;
  border-radius: 10px;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: $tanMedium;
  border-radius: 5px;
  width: 4px !important;
  height: 20px !important;
}

/* General sidebar styles */
.bm-menu {
  background: $tanLight;
  padding: 2.5em 1.5em 0;
  font-size: 1.2em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: $tanLight;
}

/* Wrapper for item list */
.bm-item-list {
  padding: 0.8em;
}

/* Styling of menu */

.bm-menu-wrap {
  top: 0;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  bottom: 0;
}

/******* Responsive ******/

@include tablet {
  .header-menu--full {
    display: none;
  }
  .header-menu--slide {
    display: block;
  }
}
